//@ts-nocheck
import React, {useState} from "react";
import back from "assets/left-arrow.svg";
import arrow from "assets/arrow-right.svg";
import TaskActionButtons from "./TaskActionButtons.component";
import translate from "components/translations/translations.wrapper.jsx";
import * as reduxSelectors from "components/store/application.reducers";
import {connect} from "react-redux";
import {DOCUMENT_TYPE, LOADING_STATUS} from "utils/constants";
import _ from "lodash";
import {useNavigate, Link} from "react-router-dom";


export const TaskDetailsSubnavbar = (props) => {


    const [openEditorState, setOpenEditor] = useState(false);
    const navigate = useNavigate();

    const navigateWithDelay = _.debounce((isForward) => {
        const nextTaskId = props.navigateBetweenTasks(props.params.taskId, isForward);
        navigate(nextTaskId, {replace: true});
    }, 500);


    const openEditor = () => setOpenEditor(true);


    const popUpEditorAvailable = props.taskDetails.externalEditorAvailable && !props.taskDetails.readonly;
    const hasEditorTab = props.taskDetails.externalEditorAvailable;

    const taskActionButtonsProps = {
        task: props.task,
        taskDetails: props.taskDetails,
        actions: props.activeActions,
        selectedTaskKeys: [props.params.taskId],
        action: props.taskAction,
        translate: props.translate,
        userSettings: props.userSettings,
        openEditorPopup: (popUpEditorAvailable && !hasEditorTab ? openEditor : false),
        backToMyTasks: props.navigateBack,
        isReadOnly: props.editorReadOnly,
        disableAll: props.isTaskDetailsLocked || props.documentDataLoading || props.workflowDetailsLoading || props.commentsLoading || props.documentPreviewLoading === LOADING_STATUS.LOADING,
        docType: props.type,
        documentVersion: props.taskDetails?.documentVersion,
        isHandled: props.task.isHandled,
    };

    //const receivedOn = moment(this.props.task.activatedDate);

    //Removed space and . from translation string too
    const applicationName = props.task.externalApplicationName || props.task.applicationTypeName || '';
    const externalApplicationNameKey = applicationName.replace(/[ .]/g, '');

    return (
        <div className="subnavbar" role={"menubar"}>
            <div className="me-4 cursor-pointer">
                <Link to={props.navigateBackPath} onClick={props.navigateBack} data-cy="taskDetail.subnavbar.backToList">
                    <img src={back} alt={props.translate("taskDetail.backToList")}/>
                </Link>
            </div>

            <div className="subnavbar-document">
                <div className="subnavbar-document-title" title={props.navigation.shortTitle}  data-cy="taskDetail.subnavbar.title">
                    <strong>{props.translate("documentType." + DOCUMENT_TYPE.asString(props.task.documentType))}</strong>
                    {(props.navigation.shortTitle && props.navigation.companyName) ?
                        <span>
                                {' '}- {props.navigation.shortTitle} → {props.navigation.companyName}
                            </span> : null}
                </div>
                <div className="subnavbar-document-subtitle mt-1" data-cy="taskDetail.subnavbar.subtitle">
                    {props.translate('taskDetail.received')}{' '}
                    {/*{receivedOn.format('DD/MM/YYYY')}*/}
                    {/*{' '}{this.props.translate('taskDetail.at')}{' '}*/}
                    {/*{receivedOn.format('HH:mm')}*/}
                    {applicationName ? <span>
                        {' '}{props.translate('taskDetail.from')}{' '}
                        {props.translationKeyExists(`taskDetail.applicationName.${externalApplicationNameKey}`) ?
                            props.translate(`taskDetail.applicationName.${externalApplicationNameKey}`) : applicationName}
                    </span> : null}
                </div>
            </div>
            <div
                className={`subnavbar-task-action-buttons ms-4 me-2${taskActionButtonsProps.disableAll ? ' disabled' : ''}`}>
                <TaskActionButtons {...taskActionButtonsProps} />
            </div>
            <div className="subnavbar-task-navigation d-flex align-items-center">
                <div>
                        <span data-cy="taskDetail.subnavbar.currentTask">
                            {props.translate('taskDetail.' + props.type + ".type")}
                            <span>
                                {" " + props.navigation.currentTask}
                            </span> / {props.navigation.total}
                        </span>
                </div>
                <div className="ms-4 me-1">
                    <button className="btn mb-0 btn-prev"
                            disabled={props.navigation.currentTask === 1}
                            title={props.translate("taskDetail.actionBar.moveToPrevious")}
                            onClick={() => navigateWithDelay(false)}
                            type="button"
                            data-cy="taskDetail.actionBar.moveToPrevious">
                        <img src={arrow} alt='previous'/>
                    </button>

                </div>
                <div>
                    <button className="btn mb-0 btn-next"
                            disabled={props.navigation.currentTask === props.navigation.total}
                            type="button"
                            title={props.translate("taskDetail.actionBar.moveToNext")}
                            onClick={() => navigateWithDelay(true)}
                            data-cy="taskDetail.actionBar.moveToNext">
                        <img src={arrow} alt='next'/>
                    </button>
                </div>
            </div>
        </div>
    );

}

const withTranslations = translate(TaskDetailsSubnavbar);

const mapStateToProps = (store) => {
    return {
        userSettings: reduxSelectors.getUsersSettings(store),
        documentDataLoading: reduxSelectors.getTaskDocumentDataLoadingStatus(store),
        workflowDetailsLoading: reduxSelectors.getWorkflowDetailsLoadingStatus(store),
        commentsLoading: reduxSelectors.getTaskCommentsLoadingStatus(store),
        documentPreviewLoading: reduxSelectors.getAttachmentsLoadingStatus(store),
        isTaskDetailsLocked:reduxSelectors.getIsTaskDetailsLocked(store),
    };
};

const mapDispatchToProps = {};

const withStore = connect(mapStateToProps, mapDispatchToProps)(withTranslations);

export default withStore;