import {
    navigateToTaskDetail,
    navigateToHistoryTaskDetail,
    navigateToProcessTaskDetail,
    navigateToDocumentOverviewDetails, navigateToMyTasks
} from '../../router/router.function.js';
import {
    getTasksForRender,
    getOneHandleOnlyFlag,
    getHistoryTasksForRender,
    getProcessOverviewTasks,
    getDocumentOverviewTasks,
} from "../../store/application.reducers";
import {PATH_TO_TASK_DETAILS} from "components/router/paths.js";

/**
 * async redux action to navigate between tasks
 * @param currentTaskKey
 * @param isForward
 * @return {Function}
 */
export function navigateBetweenTasks(currentTaskKey, isForward) {
    return function (dispatch, getState) {

        const state = getState();
        const tasks = getTasksForRender(state);
        const oneHandleOnly = getOneHandleOnlyFlag(state);

        // in case user did 'handle all', iterate only over his selection
        const iteration = oneHandleOnly ? tasks.filteredKeys : tasks.selectedKeys;
        const wantedId = getNextTaskId(iteration, currentTaskKey, isForward, dispatch, state);
        return wantedId !== null ? PATH_TO_TASK_DETAILS + wantedId : "";
    }
}


export function navigateBetweenSignableTasks(currentTaskKey, isForward) {
    return function (dispatch, getState) {

        const state = getState();
        const tasks = getTasksForRender(state);
        const oneHandleOnly = getOneHandleOnlyFlag(state);

        // in case user did 'handle all', iterate only over his selection
        const iteration = oneHandleOnly ? tasks.filteredKeys : tasks.selectedKeys;
        const wantedId = getNextTaskId(iteration, currentTaskKey, isForward, dispatch, state);
        if (wantedId !== null)
            navigateToTaskDetail(wantedId);
        else navigateToMyTasks();
    }
}

/**
 * async redux action to navigate between tasks
 * @param currentTaskKey
 * @param isForward
 * @return {Function}
 */
export function navigateBetweenHistoryTasks(currentTaskKey, isForward) {
    return function (dispatch, getState) {

        const state = getState();
        const tasks = getHistoryTasksForRender(state);
        //we iterate through all the tasks form MyHistory
        //todo navigate also between completed tasks from store
        const iteration = tasks.allTasks.map(task => {
            return task.key
        });
        const wantedId = getNextTaskId(iteration, currentTaskKey, isForward, dispatch, state);
        // if (wantedId !== null)
        //     navigateToHistoryTaskDetail(wantedId);
        return wantedId !== null ? "../taskhistory/" + wantedId : "";
    }
}

export function navigateBetweenProcessTasks(currentTaskKey, isForward) {
    return function (dispatch, getState) {

        const state = getState();
        const tasks = getProcessOverviewTasks(state);
        const iteration = tasks.allTasks.map(task => {
            return task.key
        });
        const wantedId = getNextTaskId(iteration, currentTaskKey, isForward, dispatch, state);
        // if (wantedId !== null)
        //     navigateToProcessTaskDetail(wantedId);
        return wantedId !== null ? "../processtask/" + wantedId : "";
    }
}

export function navigateBetweenDocuments(currentDocumentKey, isForward) {
    return function (dispatch, getState) {

        const state = getState();
        const tasks = getDocumentOverviewTasks(state);
        const iteration = tasks.rows.map(task => {
            return task.key
        });
        const wantedId = getNextTaskId(iteration, currentDocumentKey, isForward, dispatch, state);
        // if (wantedId !== null)
        //     navigateToDocumentOverviewDetails(wantedId);
        return wantedId !== null ? "../document/" + wantedId : "";
    }
}

function getNextTaskId(iteration, currentTaskKey, isForward, dispatch, state) {
    if (isForward && isLastInTheIteration(currentTaskKey, iteration)) {
        // show warning
        //AC-3888 remove Toasts dispatch(showNotification(translate(state, 'myTasks.atLastTask')));
        return null;
    }
    if (!isForward && _isFirstInTheIteration(currentTaskKey, iteration)) {
        // show warning
        //AC-3888 remove Toasts dispatch(showNotification(translate(state, 'myTasks.atFirstTask')));
        return null;
    }
    const currIndex = iteration.indexOf(currentTaskKey);
    const step = isForward ? 1 : -1;
    const wantedId = iteration[currIndex + step];
    return wantedId;
}

export function isLastInTheIteration(taskKey, filteredKeys) {
    const last = filteredKeys.slice().pop();
    return last === taskKey;
}

function _isFirstInTheIteration(taskKey, filteredKeys) {
    const first = filteredKeys.slice().shift();
    return first === taskKey;
}
