import  { useMemo, useState } from "react";
import NewWindow from "react-new-window";
import AttachmentsPanel from "components/taskdetails-remake/panels/attachments/AttachmentsPanel.component";
export default function NewWindowWrapper(props: any) {
    const [popupRef, setPopupRef] = useState(null);

    function onOpen(nextPopupRef: any) {
        setPopupRef(nextPopupRef);
    }

    function onUnload() {
        props.popupCloseCallback();
        setPopupRef(null);
    }

    const memoizedOptions = useMemo(
        () => ({
            ownerDocument: popupRef ? popupRef.document : undefined,
        }),
        [popupRef]
    );


    return (
        <NewWindow features={ {width: props.width, height:  props.height }}
                   title="Detached Attachments Panel"
                   onOpen={onOpen}
                   onUnload={onUnload}
        >
            {/* Do not load Document without ownerDocument reference */}
            {memoizedOptions.ownerDocument ? (
                <div className={"grid-panel-wrapper blue attachments-panel"}>
                    <div className={"attachments-panel__grid-panel grid-panel "}>
                <AttachmentsPanel attachments={props.attachments}
                                  loadingStatus={props.loadingStatus}
                                  taskDetails={props.taskDetails}
                                  translate={props.translate}
                                  width={props.width}
                                  ownerDocument={memoizedOptions}
                                  maximizePanel={props.maximizePanel}
                                  minimizePanel={props.minimizePanel}
                                  detachPanel={props.detachPanel}
                                  isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                                  isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                                  isAttachmentPanelDetached={props.isAttachmentPanelDetached}
                />
                    </div>
                </div>
            ) : (
                <p>Loading…</p>
            )}
        </NewWindow>
    );
}