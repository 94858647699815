/**
 all reducers for approval packed into final reducer, so we keep the store configuration (general)
 and reducer definition (app-specific) separated
 if you need to add reducers for approval, link them here and merge them into #approvalReducers
 */
// we would need data about user, at least if he's logged or not .. and he can switch his context, for example
import userReducer, * as userReducerExports from '../usercontext/user.reducer.js';
// translations for selected language
import translationReducer, * as translationsReducerExports from '../translations/translations.reducer.js';
// reducer for app initialization and startup
import appReducer, * as appReducerExports from '../startup/appStart.reducer';
// transient notifications
import notificationReducer, * as notificationReducerExports from '../notifications/notification.reducer';
// user's preference for splitButton
import splitButtonReducer, * as splitButtonReducerExports from '../input/splitButton.reducer';
// reducer for user applications menu items
import appMenuItemsReducer, * as appMenuItemsReducerExports from "../menu/menuAppList.reducer";

// reducer for 'multiple selection/handle' functionality of myTasks/taskDetail screens
import myTasksReducer, * as myTasksReducerExports from '../mytasks/myTasks.reducer';
import taskActionsReducer, * as taskActionReducerExports from '../mytasks/action/taskAction.reducer';
import myHistoryReducer, * as myHistoryReducerExports from '../myhistory/myHistory.reducer';
import routeConfigReducer, * as routeConfigReducerExports from '../router/route.reducer';
import myTaskDetailsReducer, * as myTaskDetailsReducerExports from '../taskdetails-remake/taskDetails.reducer';
import taskOverviewReducer, * as taskOverviewReducerExports from '../taskoverview/taskOverview.reducer';
import processOverviewReducer, * as processOverviewReducerExports from "../processoverview/processOverview.reducer";
import documentOverviewReducer, * as documentOverviewReducerExports from "../documentoverview/documentOverview.reducer";
import workflowsReducer, * as workflowsReducerExports from "../workflows/workflows.reducer";
import substitutesReducer, * as  substitutesReducerExports from "../substitutes/substitutes.reducer";
import workflowDetailsReducer, * as workflowDetailsReducerExports from "../workflowdetails/workflowdetails.reducer";
import departmentsReducer, * as departmentsReducerExports from "../departments/departments.reducer";
import orgChartReducer, * as orgChartReducerExports from "../organisationChart/organisationChart.reducer";
import projectsReducer, * as projectsReducerExports from "../projects/projects.reducer";
import costUnitsReducer, * as costUnitsReducerExports from "../costunits/costunits.reducer";
import exceptionsReducer from "../exceptions/exceptions.reducer";

import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createCompressor from 'redux-persist-transform-compress'

export const REDUX_STORE_APP_KEY = "app";
export const REDUX_STORE_ROUTE_KEY = "route";
export const STARTUP = "startup";
export const MY_TASKS = "myTasks";
export const MY_HISTORY = "myHistory";
export const TASK_OVERVIEW = "taskOverview";
export const TASK_DETAILS = "taskDetails";
export const PROCESS_OVERVIEW = "processOverview";
export const DOCUMENT_OVERVIEW = "documentOverview";
export const WORKFLOWS = "workflows";
export const SUBSTITUTES = "substitutes";
export const WORKFLOW_DETAILS = "workflowDetails";
export const DEPARTMENTS = "departments";
export const COST_UNITS = "costUnits";
export const ORG_CHART = "orgChart";
export const PROJECTS = "projects";
export const EXCEPTIONS = "exceptions";
export const APP_MENU = "appMenu";

const approvalReducers = {
    startup: userReducer, // data about user
    translations: translationReducer, // data for localization (translations)
    notifications: notificationReducer, // data for transient notifications
    splitButton: splitButtonReducer, // saved preferences for splitButtons
    taskActions: taskActionsReducer // data for task actions
};

approvalReducers[MY_TASKS] = myTasksReducer; // data for taskList
approvalReducers[MY_HISTORY] = myHistoryReducer; // data for myHistory page
approvalReducers[TASK_OVERVIEW] = taskOverviewReducer; // data for task overview page
approvalReducers[TASK_DETAILS] = myTaskDetailsReducer; //data for Task details
approvalReducers[PROCESS_OVERVIEW] = processOverviewReducer; // data for process overview page
approvalReducers[DOCUMENT_OVERVIEW] = documentOverviewReducer; // data for document overview page
approvalReducers[WORKFLOWS] = workflowsReducer;
approvalReducers[SUBSTITUTES] = substitutesReducer;
approvalReducers[WORKFLOW_DETAILS] = workflowDetailsReducer;
approvalReducers[DEPARTMENTS] = departmentsReducer;
approvalReducers[COST_UNITS] = costUnitsReducer;
approvalReducers[ORG_CHART] = orgChartReducer;
approvalReducers[PROJECTS] = projectsReducer;
approvalReducers[EXCEPTIONS] = exceptionsReducer;
approvalReducers[APP_MENU] = appMenuItemsReducer;
// startup of the app, version of the app, xsrf token for current session .. we need to extract the key, it's used/blacklisted in Store.component
approvalReducers[REDUX_STORE_APP_KEY] = appReducer;
// data for tracking navigation for AppDynamics .. we need to extract the key, it's used/blacklisted in Store.component
approvalReducers[REDUX_STORE_ROUTE_KEY] = routeConfigReducer;

// Combine Reducers ~ separate 'aspects' (attributes) stored in store, so we don't get whole state in each reducer
// http://redux.js.org/docs/api/combineReducers.html

const compressor = createCompressor();
//const sessionReducers = [MY_TASKS, MY_HISTORY, TASK_OVERVIEW,DOCUMENT_OVERVIEW,PROCESS_OVERVIEW,TASK_DETAILS,WORKFLOW_DETAILS,WORKFLOWS];
//const storageBlackList = [STARTUP, REDUX_STORE_APP_KEY, REDUX_STORE_ROUTE_KEY, COST_UNITS,DEPARTMENTS,PROJECTS,SUBSTITUTES,ORG_CHART,EXCEPTIONS,];

const config = {
    key: 'primary',
    storage,
    transforms: [compressor],
    whitelist: ['translations', MY_TASKS, 'myHistory', 'processOverview', 'documentOverview']
    //blacklist: storageBlackList.concat(sessionReducers)

};

export default persistCombineReducers(config, approvalReducers);

/*
 -------------------------------------------------------------
 state selectors - to hide store structure from the components,
 every mapStateToProps should use only these selectors, never touch the
 store structure directly, or you're doomed in case of state structure refactoring :)
 -------------------------------------------------------------
 */

//region My Tasks
export function getLoadingStatus(store) {
    return myTasksReducerExports.getLoadingStatus(store.myTasks);
}

export function getSort(store) {
    return myTasksReducerExports.getSort(store.myTasks);
}

export function getFilterText(store) {
    return myTasksReducerExports.getFilterText(store.myTasks);
}

export function getFilterCompany(store) {
    return myTasksReducerExports.getFilterCompany(store.myTasks);
}

export function getDisplayedDate(store) {
    return myTasksReducerExports.getDisplayedDate(store.myTasks);
}

/**
 * @param store
 * @return {{allTasks: *, filteredTasks: *, filteredKeys: Array, selectedKeys: Array, areAllSelected: boolean}}
 */
export function getTasksForRender(store) {
    return myTasksReducerExports.getTasksForRender(store.myTasks);
}

/**
 * get detail of one particular task
 * @param taskKey
 * @param store whole store state
 * @return {*}
 */
export function getTask(taskKey, store) {
    return myTasksReducerExports.getTask(taskKey, store.myTasks);
}

export function forceApproveDialog(store) {
    return myTasksReducerExports.forceApproveDialog(store.myTasks);
}

export function getScrollPosition(store) {
    return myTasksReducerExports.getScrollPosition(store.myTasks);
}

export function getHiddenTasks(store) {
    return myTasksReducerExports.getHiddenTasks(store.myTasks);
}

export function getTasksWithPayment(store) {
    return myTasksReducerExports.getTasksWithPayment(store.myTasks);
}

export function getActiveBatch(store) {
    return myTasksReducerExports.getBatchTasks(store.myTasks);
}

export function getAutopayURL(store) {
    return myTasksReducerExports.getAutopayURL(store.myTasks);
}

export function getShowAutopayiFrame(store) {
    return myTasksReducerExports.getShowAutopayiFrame(store.myTasks);
}

export function getBatchTasks(store) {
    return myTasksReducerExports.getBatchTasks(store.myTasks);
}

export function getHandledTasksKeys(store) {
    return myTasksReducerExports.getHandledTasksKeys(store.myTasks);
}

//endregion

//region Task Actions

export function getActionParameters(store) {
    return taskActionReducerExports.getActionParameters(store.taskActions);
}

export function areActionsQueued(store) {
    return taskActionReducerExports.areActionsQueued(store.taskActions);
}

export function getOneHandleOnlyFlag(store) {
    return taskActionReducerExports.getOneHandleOnlyFlag(store.taskActions);
}

export function getTryLoopTasksFlag(store) {
    return taskActionReducerExports.getTryLoopTasksFlag(store.taskActions);
}

export function areTaskActionsRunning(store) {
    return taskActionReducerExports.areTaskActionsRunning(store.taskActions);
}

//endregion

//region Trasnlations

export function getLanguage(store) {
    return translationsReducerExports.getLanguage(store.translations);
}

export function getDictionary(store) {
    return translationsReducerExports.getDictionary(store.translations);
}

export function getFallbackDictionary(store) {
    return translationsReducerExports.getFallbackDictionary(store.translations);
}

export function getDictionaryForLanguage(store, language) {
    return translationsReducerExports.getDictionaryForLanguage(store.translations, language);
}

export function getDateFormatForLanguage(store) {
    return translationsReducerExports.getDateFormat(store.translations);
}


//endregion

export function getNotifications(store) {
    return notificationReducerExports.getNotifications(store.notifications);
}

export function getSplitButtonPreference(store) {
    return splitButtonReducerExports.getSplitButtonPreference(store.splitButton);
}

export function getAppMenuItems(store) {
    return appMenuItemsReducerExports.getAppMenuItems(store.appMenu);
}

//region Redux Store

export function getVersion(store) {
    return appReducerExports.getVersion(store[REDUX_STORE_APP_KEY]);
}

export function getHydrated(store) {
    return appReducerExports.getHydrated(store[REDUX_STORE_APP_KEY]);
}

export function getInitialized(store) {
    return appReducerExports.getInitialized(store[REDUX_STORE_APP_KEY]);
}

export function getRouteTrackingAction(store) {
    return routeConfigReducerExports.getRouteTrackingAction(store[REDUX_STORE_ROUTE_KEY])
}

//endregion

//region Startup
/**
 * getter for startup.userConfiguration
 * @return null for not logged user
 */
export function getUsersData(store) {
    return userReducerExports.getUsersData(store.startup);
}

export function getUsersRoles(store) {
    return userReducerExports.getUsersRoles(store.startup);
}

/**
 * extract user's setting
 * @param state
 * @return {*}
 */
export function getUsersShowCommentOnApprove(store) {
    return userReducerExports.getUsersShowCommentOnApprove(store.startup);
}

export function getUsersCurrentContextId(store) {
    return userReducerExports.getUsersCurrentContextId(store.startup);
}

export function getUsersId(store) {
    return userReducerExports.getUsersId(store.startup);
}

export function getUsersCurrentLanguage(store) {
    return userReducerExports.getUsersCurrentLanguage(store.startup);
}

export function getSupportedLanguages(store) {
    return userReducerExports.getSupportedLanguages(store.startup);
}

export function getHelpCentreConfiguration(store) {
    return userReducerExports.getHelpCentreConfiguration(store.startup);
}

export function getUsersCurrentBusinessFeatures(store) {
    return userReducerExports.getUsersCurrentBusinessFeatures(store.startup);
}

export function getUsersLoggedInData(store) {
    return userReducerExports.getUsersLoggedInData(store.startup);
}

export function getAvailableContextsCount(store) {
    return userReducerExports.getAvailableContextsCount(store.startup);
}

export function getUsersSettings(store) {
    return userReducerExports.getUsersSettings(store.startup);
}

export function getErrorMessageVisibility(store) {
    return userReducerExports.getErrorMessageVisibility(store.startup)
}

export function getNumberOfConnectionAttempts(store) {
    return userReducerExports.getNumberOfConnectionAttempts(store.startup)
}

//endregion

//region My History

export function getHistoryTasksForRender(store) {
    return myHistoryReducerExports.getHistoryTasksForRender(store.myHistory);
}

export function getHistoryLoadingStatus(store) {
    return myHistoryReducerExports.getHistoryLoadingStatus(store.myHistory);
}

export function getHistoryProcessFilter(store) {
    return myHistoryReducerExports.getHistoryProcessFilter(store.myHistory);
}

export function getToggleAdvancedSearch(store) {
    return myHistoryReducerExports.gettoggleAdvancedSearch(store.myHistory);
}

export function getHistoryFilter(store) {
    return myHistoryReducerExports.getHistoryFilter(store.myHistory)
}

export function getHistorySortColumn(store) {
    return myHistoryReducerExports.getHistorySortColumn(store.myHistory)
}

export function shouldPreventHistoryReload(store) {
    return myHistoryReducerExports.shouldPreventReload(store.myHistory)
}

export function getHistoryScrollTop(store) {
    return myHistoryReducerExports.getHistoryScrollTop(store.myHistory)
}

export function getCostUnitsOptions(store) {
    return myHistoryReducerExports.getCostUnitsOptions(store.myHistory)
}

//endregion

//region Task Details

export function getZoomPercentage(store) {
    return myTaskDetailsReducerExports.getZoomPercentage(store.taskDetails)
}

export function getImageZoomPercentage(store) {
    return myTaskDetailsReducerExports.getImageZoomPercentage(store.taskDetails)
}

export function getTaskDetails(store) {
    return myTaskDetailsReducerExports.getTaskDetails(store.taskDetails)
}

export function getIsTaskDetailsLocked(store) {
    return myTaskDetailsReducerExports.getIsTaskDetailsLocked(store.taskDetails)
}

export function getIframeHasUnsavedChanges(store) {
    return myTaskDetailsReducerExports.getIframeHasUnsavedChanges(store.taskDetails)
}

export function getCommentsHaveUnsavedChanges(store) {
    return myTaskDetailsReducerExports.getCommentsHaveUnsavedChanges(store.taskDetails)
}

export function getVBNXTSaveDiscardFlag(store) {
    return myTaskDetailsReducerExports.getVBNXTSaveDiscardFlag(store.taskDetails)
}

export function getTaskAttachments(store) {
    return myTaskDetailsReducerExports.getAttachments(store.taskDetails)
}

export function getTaskAttachmentsForRender(store) {
    return myTaskDetailsReducerExports.getTaskAttachmentsForRender(store.taskDetails)
}

export function getImagesForRender(store) {
    return myTaskDetailsReducerExports.getImagesForRender(store.taskDetails)
}

export function getAttachmentsDownloadLinks(store) {
    return myTaskDetailsReducerExports.getAttachmentsDownloadLinks(store.taskDetails)
}

export function getDetails(store) {
    return myTaskDetailsReducerExports.getDetails(store.taskDetails)
}

export function getSelectedDocument(store) {
    return myTaskDetailsReducerExports.getSelectedDocument(store.taskDetails)
}

export function getAttachmentsLoadingStatus(store) {
    return myTaskDetailsReducerExports.getDetailsLoadingStatus(store.taskDetails)
}

export function getDocumentEditedFlag(store) {
    return myTaskDetailsReducerExports.getDocumentEditedFlag(store.taskDetails);
}

export function getDocumentRotation(store) {
    return myTaskDetailsReducerExports.getDocumentRotation(store.taskDetails);
}

export function getTaskWorkflowDetails(store) {
    return myTaskDetailsReducerExports.getTaskWorkflowDetails(store.taskDetails);
}

export function getWorkflowDetailsLoadingStatus(store) {
    return myTaskDetailsReducerExports.getWorkflowDetailsLoadingStatus(store.taskDetails);
}

export function getTaskWorkflowHistory(store) {
    return myTaskDetailsReducerExports.getTaskWorkflowHistory(store.taskDetails);
}

export function getTaskDocumentData(store) {
    return myTaskDetailsReducerExports.getTaskDocumentData(store.taskDetails);
}

export function getTaskDocumentDataLoadingStatus(store) {
    return myTaskDetailsReducerExports.getTaskDocumentDataLoadingStatus(store.taskDetails);
}

export function getTaskCommentsAndEvents(store) {
    return myTaskDetailsReducerExports.getTaskCommentsAndEvents(store.taskDetails);
}

export function getTaskComments(store) {
    return myTaskDetailsReducerExports.getTaskComments(store.taskDetails);
}

export function getTaskCommentsLoadingStatus(store) {
    return myTaskDetailsReducerExports.getTaskCommentsLoadingStatus(store.taskDetails);
}

export function getTaskCurrentComment(store) {
    return myTaskDetailsReducerExports.getTaskCurrentComment(store.taskDetails);
}

export function getTaskUnsavedComment(store) {
    return myTaskDetailsReducerExports.getTaskUnsavedComment(store.taskDetails);
}

export function getTaskCommentSavingStatus(store) {
    return myTaskDetailsReducerExports.getTaskCommentSavingStatus(store.taskDetails);
}

export function getTaskCommentsCount(store) {
    return myTaskDetailsReducerExports.getTaskCommentsCount(store.taskDetails);
}

export function getAllowExtraComments(store) {
    return myTaskDetailsReducerExports.getAllowExtraComments(store.taskDetails);
}

//endregion

//region Task Overview

export function getOverviewCounters(store) {
    return taskOverviewReducerExports.getOverviewCounters(store.taskOverview)
}

export function getOverviewActiveCounter(store) {
    return taskOverviewReducerExports.getOverviewActiveCounter(store.taskOverview)
}

export function getOverviewFilter(store) {
    return taskOverviewReducerExports.getOverviewFilter(store.taskOverview)
}

export function getOverviewTasks(store) {
    return taskOverviewReducerExports.getOverviewTasks(store.taskOverview)
}

export function getOverviewSortingLogic(store) {
    return taskOverviewReducerExports.getOverviewSortingLogic(store.taskOverview)
}

export function getOverviewUserRoles(store) {
    return taskOverviewReducerExports.getOverviewUserRoles(store.taskOverview)
}

export function getOverviewLoadingStatus(store) {
    return taskOverviewReducerExports.getOverviewLoadingStatus(store.taskOverview)
}

export function getOverviewSelectedTasks(store) {
    return taskOverviewReducerExports.getOverviewSelectedTasks(store.taskOverview)
}

//endregion

//region Process Overview

export function getProcessOverviewLoadingStatus(store) {
    return processOverviewReducerExports.getOverviewLoadingStatus(store.processOverview)
}

export function getProcessOverviewLoadingError(store) {
    return processOverviewReducerExports.getOverviewLoadingError(store.processOverview)
}

export function getProcessOverviewTasks(store) {
    return processOverviewReducerExports.getProcessOverviewTasks(store.processOverview)
}

export function getProcessOverviewAsyncLoading(store) {
    return processOverviewReducerExports.getProcessOverviewAsyncLoading(store.processOverview)
}

export function getProcessOverviewFilter(store) {
    return processOverviewReducerExports.getProcessOverviewFilter(store.processOverview)
}

export function getProcessToggleAdvancedSearch(store) {
    return processOverviewReducerExports.getProcessToggleAdvancedSearch(store.processOverview)
}

export function getProcessCostUnitsOptions(store) {
    return processOverviewReducerExports.getCostUnitsOptions(store.processOverview)
}

export function shouldPreventProcessReload(store) {
    return processOverviewReducerExports.shouldPreventProcessReload(store.processOverview)
}

export function getProcessScrollTop(store) {
    return processOverviewReducerExports.getProcessScrollTop(store.processOverview)
}


//endregion

//region Document Overview
export function getDocumentOverviewActiveCounter(store) {
    return documentOverviewReducerExports.getDocumentOverviewActiveCounter(store.documentOverview)
}

export function getDocumentOverviewCounters(store) {
    return documentOverviewReducerExports.getOverviewCounters(store.documentOverview)
}

export function getDocumentOverviewTasks(store) {
    return documentOverviewReducerExports.getOverviewTasksForRender(store.documentOverview)
}

export function getDocumentOverviewLoadingStatus(store) {
    return documentOverviewReducerExports.getLoadingStatus(store.documentOverview)
}

export function getDocumentOverviewDocumentType(store) {
    return documentOverviewReducerExports.getDocumentOverviewDocumentType(store.documentOverview)
}

export function getHasDocumentKeyChanged(store) {
    return documentOverviewReducerExports.getHasDocumentKeyChanged(store.documentOverview)
}

export function getDocumentOverviewFilter(store) {
    return documentOverviewReducerExports.getDocumentOverviewFilter(store.documentOverview)
}

export function getDocumentOverviewSelectedTasks(store) {
    return documentOverviewReducerExports.getDocumentOverviewSelectedTasks(store.documentOverview)
}

export function getDocumentOverviewSortingLogic(store) {
    return documentOverviewReducerExports.getDocumentOverviewSortingLogic(store.documentOverview)
}

export function getDocumentOverviewCompanies(store) {
    return documentOverviewReducerExports.getDocumentOverviewCompanies(store.documentOverview)
}

export function shouldPreventDocumentReload(store) {
    return documentOverviewReducerExports.shouldPreventDocumentReload(store.documentOverview)
}

export function getDocumentScrollTop(store) {
    return documentOverviewReducerExports.getDocumentScrollTop(store.documentOverview)
}

//endregion

//region Workflows

export function getLastCreatedWorkflow(store) {
    return workflowsReducerExports.getLastCreatedWorkflow(store.workflows);
}
export function getWorkflowsLoadingStatus(store) {
    return workflowsReducerExports.getWorkflowsLoadingStatus(store.workflows);
}

export function getWorkflowsForRender(store) {
    return workflowsReducerExports.getWorkflowsForRender(store.workflows);
}

export function getWorkflows(store) {
    return workflowsReducerExports.getWorkflows(store.workflows);
}

export function getWorkflowsPreventAction(store) {
    return workflowsReducerExports.getPreventAction(store.workflows);
}

export function getWorkflowsAmountLimits(store) {
    return workflowsReducerExports.amountLimits(store.workflows);
}

export function getWorkflowsDocTypes(store) {

    return workflowsReducerExports.getDocTypes(store.workflows);
}

export function getWorkflowHistory(store) {
    return workflowsReducerExports.getWorkflowHistory(store.workflows);
}

export function getWorkflowHistoryLoadingStatus(store) {
    return workflowsReducerExports.getWorkflowHistoryLoadingStatus(store.workflows);
}

export function getConfigRoles(store) {
    return workflowDetailsReducerExports.getConfigRoles(store.workflowDetails);
}

export function getCostUnits(store) {
    return workflowDetailsReducerExports.getConfigRoles(store.workflowDetails);
}

export function getActiveWorkflow(store) {
    return workflowDetailsReducerExports.getActiveWorkflow(store.workflowDetails);
}

export function getOrganizationChart(store) {
    return workflowDetailsReducerExports.getOrganizationChart(store.workflowDetails);
}

export function getWorkflowChangedStatus(store) {
    return workflowDetailsReducerExports.getWorkflowChangedStatus(store.workflowDetails);
}

export function getExceptionUsers(store) {
    return workflowDetailsReducerExports.getExceptionUsers(store.workflowDetails);
}

export function getChangesDoneByUser(store) {
    return workflowDetailsReducerExports.getChangesDoneByUser(store.workflowDetails);
}

export function getOldWorkflowDetails(store) {
    return workflowDetailsReducerExports.getOldWorkflowDetails(store.workflowDetails);
}

//endregion

//region Substitutes

export function getSubstitutesActiveFilter(store) {
    return substitutesReducerExports.getActiveFilter(store.substitutes);
}

export function getSubstitutesSearchValue(store) {
    return substitutesReducerExports.getSearchValue(store.substitutes);
}

export function getSubstitutesForRender(store) {
    return substitutesReducerExports.getSubstitutesForRender(store.substitutes);
}

export function getSubstitutesSorting(store) {
    return substitutesReducerExports.getSorting(store.substitutes);
}

export function getSubstitutesLoadingStatus(store) {
    return substitutesReducerExports.getLoadingStatus(store.substitutes);
}


//endregion

export function getDepartments(store) {
    return departmentsReducerExports.getDepartmentsForRender(store.departments);
}

export function getDepartmentsFilter(store) {
    return departmentsReducerExports.getDepartmentsFilter(store.departments);
}

export function getPossibleDepartmentManagers(store) {
    return departmentsReducerExports.getFormatedPossibleManagers(store.departments);
}


export function getOrgChartUsers(store) {
    return orgChartReducerExports.getOrgChartUsersForRender(store.orgChart);
}

export function getOrgChartSelectedNode(store) {
    return orgChartReducerExports.getOrgChartSelectedNode(store.orgChart);
}

export function getOrgChartSelectedDate(store) {
    return orgChartReducerExports.getOrgChartSelectedDate(store.orgChart);
}

export function getOrgChartRoles(store) {
    return orgChartReducerExports.getOrgChartRoles(store.orgChart);
}

export function getOrgChartFilters(store) {
    return orgChartReducerExports.getOrgChartFilters(store.orgChart);
}

export function getOrgChartTree(store) {
    return orgChartReducerExports.getOrgChartTree(store.orgChart);
}

export function getOrgChartSortingLogic(store) {
    return orgChartReducerExports.getOrgChartSortingLogic(store.orgChart);
}

export function getOrgChartSortingColumn(store) {
    return orgChartReducerExports.getOrgChartSortingColumn(store.orgChart);
}

export function getOrgChartUsersLoadingStatus(store) {
    return orgChartReducerExports.getOrgChartUsersLoadingStatus(store.orgChart);
}

export function getOrgChartChildNodes(store) {
    return orgChartReducerExports.getOrgChartChildNodes(store.orgChart);
}

export function getProjectsUsers(store) {
    return projectsReducerExports.getProjectsUsers(store.projects);
}

export function getProjectsRoles(store) {
    return projectsReducerExports.getProjectsRoles(store.projects);
}

export function getSeletedCostUnitTypes(store) {
    return costUnitsReducerExports.selectedCostUnitTypes(store.costUnits);
}

export function getCostUnitTypes(store) {
    return costUnitsReducerExports.costUnitTypes(store.costUnits);
}

export function getCostUnitTypesVersion(store) {
    return costUnitsReducerExports.costUnitTypesVersion(store.costUnits);
}

export function getCostUnitRoles(store) {
    return costUnitsReducerExports.costUnitRoles(store.costUnits);
}

export function getCostUnitWarnings(store) {
    return costUnitsReducerExports.costUnitWarnings(store.costUnits);
}

export function getApproversTree(store) {
    return costUnitsReducerExports.approversTree(store.costUnits);
}

export function getApproversNodes(store) {
    return costUnitsReducerExports.approversNodes(store.costUnits);
}

export function getCostUnitRoleTypes(store) {
    return costUnitsReducerExports.costUnitRoleTypes(store.costUnits);
}

export function getCostUnitBranches(store) {
    return costUnitsReducerExports.costUnitBranches(store.costUnits);
}

export function getApproversLoadingStatus(store) {
    return costUnitsReducerExports.getApproversLoadingStatus(store.costUnits);
}

export function getRolesSort(store) {
    return costUnitsReducerExports.getRolesSort(store.costUnits);
}

export function getCostUnitsDocTypes(store) {
    return costUnitsReducerExports.getCostUnitsDocTypes(store.costUnits);
}
