import AttachmentsPreview from "./AttachmentsPreview.component";
import BypassCIRContainer from "./BypassCIRContainer.component";
import './attachments.scss';
import {launchDarkly} from 'utils/launchDarkly';
import {AttachmentsPanelProps} from "types/attachment";

const AttachmentsPanel = (props: AttachmentsPanelProps) => {
    const bypassCIR: null | boolean = launchDarkly.userClient && launchDarkly.userClient.variation("bypass-cir", false) as boolean;

    return (
        <div
            className={`${bypassCIR ? "p-0 attachments-preview-modal" : ""} grid-attachments-preview`}
            style={{width: props.width + "px"}}
            data-cy="taskDetail.attachmentsPanel">
            {bypassCIR ?
                <BypassCIRContainer data-testid={"bypass-cir-container"}
                                    isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                                    isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                                    isAttachmentPanelDetached={props.isAttachmentPanelDetached}
                                    maximizePanel={props.maximizePanel}
                                    minimizePanel={props.minimizePanel}
                                    detachPanel={props.detachPanel}
                                    ownerDocument={props.ownerDocument}/>
                :
                <AttachmentsPreview
                    attachments={props.attachments}
                    loadingStatus={props.loadingStatus}
                    taskDetails={props.taskDetails}
                    translate={props.translate}
                    isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                    isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                    isAttachmentPanelDetached={props.isAttachmentPanelDetached}
                    maximizePanel={props.maximizePanel}
                    minimizePanel={props.minimizePanel}
                    detachPanel={props.detachPanel}
                />
            }
        </div>
    );
}

export default AttachmentsPanel;