import React from "react";
import maximizePanelDisabled from "components/taskdetails-remake/icons/maximize-disabled_dark.svg";
import maximizePanelImg from "components/taskdetails-remake/icons/maximize_dark.svg";
import minimizePanelDisabled from "components/taskdetails-remake/icons/minimize-disabled_dark.svg";
import minimizePanelImg from "components/taskdetails-remake/icons/minimize_dark.svg";
import translate from "components/translations/translations.wrapper";
import { launchDarkly } from "utils/launchDarkly";


interface MinMaxGroupProps {
    translate: React.FC;
    isAttachmentPanelMaxWidth: boolean;
    isAttachmentPanelWidthDefault: boolean;
    isAttachmentPanelDetached: boolean;
    maximizePanel: () => void;
    minimizePanel: () => void;
    detachPanel: (detach: boolean) => void;
}


export const MinMaxGroup = ({
                                translate,
                                isAttachmentPanelMaxWidth,
                                isAttachmentPanelWidthDefault,
                                isAttachmentPanelDetached,
                                maximizePanel,
                                minimizePanel,
                                detachPanel,
                            }: MinMaxGroupProps): React.ReactElement => {
    const useDetachAttachmentViewer = launchDarkly.userClient && launchDarkly.userClient.variation("fe-detach-attachment-viewer", false);

    return <div className="min-max-btn-group">
        {useDetachAttachmentViewer &&
            <button className="action_btn cursor-pointer m-0 pe-0"
                    title={translate("taskDetail.detach")?.toString()}
                    type="button">
                <span
                    className="vismaicon vismaicon-detach"
                    onClick={() => detachPanel(!isAttachmentPanelDetached)}
                />
            </button>
        }
        <button className="action_btn cursor-pointer m-0 pe-0"
                title={translate("taskDetail.maximize")?.toString()}
                type="button">
            <img
                className={`dark details__action-button${isAttachmentPanelMaxWidth ? " disabled" : ""}`}
                src={isAttachmentPanelMaxWidth ? maximizePanelDisabled : maximizePanelImg}
                alt={translate("taskDetail.maximize")?.toString()}
                onClick={isAttachmentPanelMaxWidth ? undefined : maximizePanel}
            />
        </button>
        <button className="action_btn cursor-pointer mx-0 px-0"
                title={translate("taskDetail.minimize")?.toString()}
                type="button">
            <img
                className={`dark details__action-button ${isAttachmentPanelWidthDefault ? " disabled" : ""}`}
                src={isAttachmentPanelWidthDefault ? minimizePanelDisabled : minimizePanelImg}
                alt={translate("taskDetail.minimize")?.toString()}
                onClick={isAttachmentPanelWidthDefault ? undefined : minimizePanel}
            />
        </button>
    </div>;

};

export default translate(MinMaxGroup);