// user.action
export const LOGGED_IN_TYPE = "LOGGED_IN_USER";
export const LOGGED_OUT_TYPE = "LOGGED_OUT_USER";

/**
 * user may go for example into old UI and log out there, log in under different user and return back to Approval Web
 * @type {string}
 */
export const DIFFERENT_USER = "DIFFERENT_USER_LOGGED";

// translations.action
export const TRANSLATIONS_LOADED = "TRANSLATIONS_LOADED";


/**
 * this one is emited once we have hydrated store and checked against backend, that hydrated data are valid against user context
 *
 * it's emited from loadUserData in user.actions, and isn't persisted in store at all
 */
export const APP_START = "APP_STARTED";

/**
 * action during application initialization for detection of version change
 */
export const SAVE_VERSION = "VERSION_DETECTED";

/**
 * used to display transient notification
 */
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
/**
 * used to hide transient notification
 */
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

/**
 * remember user's preferred splitButton action
 */
export const SAVE_SPLITBUTTON_PREFERENCE = "SAVE_SPLITBUTTON_PREFERENCE";
/**
 * retrieving user applications menu items
 */
export const APP_MENU_ITEMS_LOADING = "APP_MENU_ITEMS_LOADING";
export const APP_MENU_ITEMS_LOADED = "APP_MENU_ITEMS_LOADED";
export const APP_MENU_ITEMS_ERROR = "APP_MENU_ITEMS_ERROR";

/**
 * mark the tasks as loading
 */
export const SAVE_MY_TASKS_LOADING = "TASKS_LOADING";
export const SAVE_MY_TASKS_LOADING_DONE = "TASKS_LOADING_DONE";
/**
 * save the result of task load
 */
export const SAVE_MY_TASKS_DONE = "TASKS_SAVE";
/**
 * save the error from task loading
 */
export const SAVE_MY_TASKS_ERROR = "TASKS_ERROR";
/**
 * change the sorting of the task list
 */
export const CHANGE_SORT = "TASKS_CHANGE_SORT";
/**
 * change the search filter of the task list
 */
export const CHANGE_SEARCH_FILTER = "TASKS_CHANGE_SEARCH_FILTER";
/**
 * chage the company filter of the task list
 */
export const CHANGE_COMPANY_FILTER = "TASKS_CHANGE_COMPANY_FILTER";
/**
 * 'select all' of the task list
 */
export const TOGGLE_ALL_SELECTION = "TASK_TOGGLE_ALL";
/**
 * change the selection of particular task
 */
export const TOGGLE_TASK_SELECTION = "TASK_TOGGLE";
/**
 * while in the iteration, last selected task handled returns back to myTasks
 * otherwise first handled tasks returns to myTasks
 */
export const UPDATE_ONE_HANDLE_ONLY_FLAG = "TASKS_ONLY_ONE_HANDLE";

/**
 * websockets events updates on my tasks list
 * */
export const MY_TASKS_ADD = "MY_TASKS_ADD";

export const MY_TASKS_REMOVED = "MY_TASKS_REMOVED";

export const TASKS_HANDLED_IN_BACKGROUND = "TASKS_HANDLED_IN_BACKGROUND";


/**
 * execute asynchronous task action (eg show the dialog)
 * @type {string}
 */
export const TASK_ACTION_ASYNC = "TASK_ACTION_ASYNC";
/**
 * clear the data for asynchronous action from store
 */
export const TASK_ACTION_FINISHED = "TASK_ACTION_FINISHED";

export const CHANGE_DISPLAYED_DATE = "CHANGE_DISPLAYED_DATE";

export const APPROVE_ACTION_RUNNING = "APPROVE_ACTION_RUNNING";

export const APPROVE_ACTION_STOPED = "APPROVE_ACTION_STOPED";

/**
 * when user handle task, remove it from the task list, so he won't stumble on it when navigating forth/back
 */
export const TASK_REMOVE_ONE = "TASK_REMOVE";

export const SAVE_MY_HISTORY_LOADING = "HISTORY_LOADING";
export const SAVE_MY_HISTORY_DONE = "HISTORY_LOADED";
/*
 *when the user switches between the all, active and complete processes
 * */
export const HISTORY_PROCESS_TYPE_CHANGED = "HISTORY_PROCESS_TYPE_CHANGED";
/*
 * used to remember the toggling state for the advanced history filter
 * */
export const HISTORY_ADVANCED_SEARCH_OPEN = "HISTORY_ADVANCED_SEARCH_OPEN";
/*
 * used for changes of the grid and/or advanced filter changes
 * */
export const HISTORY_FILTER_CHANGED = "HISTORY_FILTER_CHANGED";

export const HISTORY_SORT_COLUMN_CHANGED = "HISTORY_SORT_COLUMN_CHANGED";

/*
 * needed for the new History pages
 * */
export const HISTORY_GET_NEXT_PAGE = "HISTORY_GET_NEXT_PAGE";
/**
 * used to track VirtualPages in AppDynamics
 */
export const VIRTUAL_PAGE_ROUTING = "VIRTUAL_PAGE_ROUTING";
/**
 * used to remove the page tracking event from the store
 */
export const VIRTUAL_PAGE_ROUTING_END = "VIRTUAL_PAGE_ROUTING_END";

/*
 * used when the user changes the zoom for the attachments of a task
 * */
export const CHANGE_ZOOM_PERCENTAGE = "CHANGE_ZOOM_PERCENTAGE";
export const CHANGE_IMAGE_ZOOM_PERCENTAGE = "CHANGE_IMAGE_ZOOM_PERCENTAGE";
export const CACHE_ATTACHMENTS_IMAGES_URLS = "CACHE_ATTACHMENTS_IMAGES_URLS";

export const TASK_DETAILS_LOADED = "TASK_DETAILS_LOADED";

export const WORKFLOW_DETAILS_LOADED = "WORKFLOW_DETAILS_LOADED";

export const WORKFLOW_DETAILS_LOADING = "WORKFLOW_DETAILS_LOADING";

export const WORKFLOW_HISTORY_LOADED = "WORKFLOW_HISTORY_LOADED";

export const CHANGE_SELECTED_DOCUMENT = "CHANGE_SELECTED_DOCUMENT";

export const TASK_DETAILS_LOADING = "TASK_DETAILS_LOADING";

export const TASK_DETAILS_LOADING_ERROR = "TASK_DETAILS_LOADING_ERROR";

export const WORKFLOW_DETAILS_LOADING_ERROR = "WORKFLOW_DETAILS_LOADING_ERROR";

export const TASK_DOCUMENT_DATA_LOADED = "TASK_DOCUMENT_DATA_LOADED";

export const TASK_DOCUMENT_DATA_LOADING = "TASK_DOCUMENT_DATA_LOADING";

export const TASK_DOCUMENT_DATA_LOADING_ERROR = "TASK_DOCUMENT_DATA_LOADING_ERROR";

export const CHANGE_UNSAVED_COMMENT = "CHANGE_UNSAVED_COMMENT";

export const TASK_COMMENTS_LOADING = "TASK_COMMENTS_LOADING";

export const TASK_COMMENTS_LOADED = "TASK_COMMENTS_LOADED";

export const TASK_COMMENT_START_SAVING = "TASK_COMMENT_START_SAVING";

export const TASK_COMMENT_SAVING = "TASK_COMMENT_SAVING";

export const TASK_COMMENT_SAVING_ERROR = "TASK_COMMENT_SAVING_ERROR";

export const TASK_COMMENT_SAVED = "TASK_COMMENT_SAVED";

export const COST_UNITS_LOADED = "COST_UNITS_LOADED";

export const TASK_OVERVIEW_LOADING = "TASK_OVERVIEW_LOADING";

export const TASK_OVERVIEW_LOADED = "TASK_OVERVIEW_LOADED";

export const ACTIVE_COUNTER_CHNAGED = "ACTIVE_COUNTER_CHNAGED";

export const OVERVIEW_FILTER_CHANGED = "OVERVIEW_FILTER_CHANGED";

export const OVERVIEW_COUNTERS_LOADED = "OVERVIEW_COUNTERS_LOADED";

export const LOAD_MORE_OVERVIEW = "LOAD_MORE_OVERVIEW";

export const OVERVIEW_SORT_COLUMN_CHANGED = "OVERVIEW_SORT_COLUMN_CHANGED";

export const OVERVIEW_TASK_SELECTED = "OVERVIEW_TASK_SELECTED";

export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";

export const ENFORCE_APPROVE_DIALOG = "ENFORCE_APPROVE_DIALOG";

export const SCROLL_POSITION = "SCROLL_POSITION";

export const PROCESS_OVERVIEW_USER_ROLES_LOADED = "PROCESS_OVERVIEW_USER_ROLES_LOADED";

export const PROCESS_OVERVIEW_LOADING = "PROCESS_OVERVIEW_LOADING";

export const PROCESS_OVERVIEW_LOAD_MORE = "PROCESS_OVERVIEW_LOAD_MORE";

export const PROCESS_OVERVIEW_INITIALIZE = "PROCESS_OVERVIEW_INITIALIZE";

export const PROCESS_OVERVIEW_LOAD_COMPLETED = "PROCESS_OVERVIEW_LOAD_COMPLETED";

export const PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR = "PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR";

export const PROCESS_OVERVIEW_FILTER_CHANGED = "PROCESS_OVERVIEW_FILTER_CHANGED";

export const PROCESS_ADVANCED_SEARCH_OPEN = "PROCESS_ADVANCED_SEARCH_OPEN";

export const PROCESS_OVERVIEW_COST_UNITS_LOADED = "PROCESS_OVERVIEW_COST_UNITS_LOADED";

export const PROCESS_PREVENT_DATA_RELOAD = "PROCESS_PREVENT_DATA_RELOAD";

export const PROCESS_SAVE_SCROLL_POSITION = "PROCESS_SAVE_SCROLL_POSITION";

export const HAS_EDITED_DOCUMENT = "HAS_EDITED_DOCUMENT";

export const DOCUMENT_OVERVIEW_LOADED = "DOCUMENT_OVERVIEW_LOADED";

export const DOCUMENT_OVERVIEW_LOADING = "DOCUMENT_OVERVIEW_LOADING";

export const DOCUMENT_OVERVIEW_DOCTYPE_CHANGED = "DOCUMENT_OVERVIEW_DOCTYPE_CHANGED";

export const DOCUMENT_OVERVIEW_TASK_SELECTED = "DOCUMENT_OVERVIEW_TASK_SELECTED";

export const DOCUMENT_OVERVIEW_FILTER_CHANGED = "DOCUMENT_OVERVIEW_FILTER_CHANGED";

export const DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED = "DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED";

export const LOAD_MORE_DOCUMENT_OVERVIEW = "LOAD_MORE_DOCUMENT_OVERVIEW";

export const DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT = "DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT";

export const HIDDEN_TASKS_LOADED = "HIDDEN_TASKS_LOADED";

export const DOCUMENT_DETAILS_LOADED = "DOCUMENT_DETAILS_LOADED";

export const ADD_TO_ACTION_QUEUE = "ADD_TO_ACTION_QUEUE";

export const REMOVE_ACTION_FROM_QUEUE = "REMOVE_ACTION_FROM_QUEUE";

export const CHANGE_DOCUMENT_KEY = "CHANGE_DOCUMENT_KEY";

export const RESET_CHANGE_DOCUMENT_KEY = "RESET_CHANGE_DOCUMENT_KEY";

export const CHANGE_DISPLAY_LAYOUT = "CHANGE_DISPLAY_LAYOUT";

export const PREVENT_DATA_RELOAD = "PREVENT_DATA_RELOAD";

export const HISTORY_SAVE_SCROLL_POSITION = "HISTORY_SAVE_SCROLL_POSITION";

export const DOCUMENT_PREVENT_DATA_RELOAD = "DOCUMENT_PREVENT_DATA_RELOAD";

export const DOCUMENT_SAVE_SCROLL_POSITION = "DOCUMENT_SAVE_SCROLL_POSITION";

export const LOOP_TASKS_FLAG = "LOOP_TASKS_FLAG";

export const ADD_RULE_CONFIGURATION = "ADD_RULE_CONFIGURATION";

export const REMOVE_RULE_CONFIGURATION = "REMOVE_RULE_CONFIGURATION";

export const UPDATE_RULE_CONFIGURATION = "UPDATE_RULE_CONFIGURATION";

export const WORKFLOWS_PREVENT_ACTION = "WORKFLOWS_PREVENT_ACTION";
export const WORKFLOWS_LOADED = "WORKFLOWS_LOADED";

export const WORKFLOWS_LOADING = "WORKFLOWS_LOADING";

export const CONFIG_ROLES_LOADED = "CONFIG_ROLES_LOADED";

export const ALL_COST_UNITS_LOADED = "ALL_COST_UNITS_LOADED";

export const SORT_WORKFLOW_STEPS = "SORT_WORKFLOW_STEPS";

export const SET_ACTIVE_WORKFLOW = "SET_ACTIVE_WORKFLOW";

export const SET_4_EYES_ENABLED = "SET_4_EYES_ENABLED";

export const SET_4_EYES_FALLBACK_USERS = "SET_4_EYES_FALLBACK_USERS";

export const SUBSTITUES_LOADED = "SUBSTITUES_LOADED";

export const SUBSTITUES_LOADING = "WORKFLOWS_LOADING";

export const SUBSTITUES_ACTIVE_FILTER_CHANGE = "SUBSTITUES_ACTIVE_FILTER_CHANGE";

export const SUBSTITUES_SEARCH_VALUE = "SUBSTITUES_SEARCH_VALUE";

export const SUBSTITUE_REMOVED = "SUBSTITUE_REMOVED";

export const SUBSTITUES_SORT = "SUBSTITUES_SORT";

export const MORE_SUBSTITUTES_LOADED = "MORE_SUBSTITUTES_LOADED";

export const STARTUP_ERROR = "STARTUP_ERROR";

export const SET_ACTION = "SET_ACTION";

export const ORGANIZATION_CHART_LOADED = "ORGANIZATION_CHART_LOADED";

export const ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED = "ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED";

export const SET_STEP_SETTINGS = "SET_STEP_SETTINGS";

export const SET_STEP_CHANGES = "SET_STEP_CHANGES";

export const SET_RULE_TYPE = "SET_RULE_TYPE";

export const REMOVE_ACTIVE_WORKFLOW = "REMOVE_ACTIVE_WORKFLOW";

export const RENAME_WORKFLOW_RULE = "RENAME_WORKFLOW_RULE";

export const REMOVE_STEP = "REMOVE_STEP";

export const SET_STEP_EXCEPTIONS = "SET_STEP_EXCEPTIONS";

export const STEP_USERS_LOADED = "STEP_USERS_LOADED";

export const DEPARTMENTS_LOADED = "DEPARTMENTS_LOADED";

export const DEPARTMENTS_FILTER_CHANGED = "DEPARTMENTS_FILTER_CHANGED";

export const ASSIGNED_DEPARTMENTS_LOADED = "ASSIGNED_DEPARTMENTS_LOADED";

export const COST_UNIT_TYPES_LOADED = "COST_UNIT_TYPES_LOADED";

export const COST_UNIT_TYPE_SELECTED = "COST_UNIT_TYPE_SELECTED";

export const COST_UNIT_TYPE_UNSELECTED = "COST_UNIT_TYPE_UNSELECTED";

export const COST_UNIT_TYPE_REORDERED = "COST_UNIT_TYPE_REORDERED";

export const COST_UNIT_TYPES_SET = "COST_UNIT_TYPES_SET";

export const COST_UNIT_TYPES_SAVED = "COST_UNIT_TYPES_SAVED";

export const ORG_CHART_TREE_LOADED = "ORG_CHART_TREE_LOADED";

export const ORG_CHART_USERS_LOADED = "ORG_CHART_USERS_LOADED";

export const ORG_CHART_ROLES_LOADED = "ORG_CHART_ROLES_LOADED";

export const ORG_CHART_CHANGE_SELECTED_NODE = "ORG_CHART_CHANGE_SELECTED_NODE";

export const ORG_CHART_CHANGE_SELECTED_DATE = "ORG_CHART_CHANGE_SELECTED_DATE";

export const ORG_CHART_FILTERS_CHANGED = "ORG_CHART_FILTERS_CHANGED";

export const ORG_CHART_SORT_COLUMN_CHANGED = "ORG_CHART_SORT_COLUMN_CHANGED";

export const ORG_CHART_TREE_LOADING = "ORG_CHART_TREE_LOADING";

export const ORG_CHART_CHILDREN_LOADED = "ORG_CHART_CHILDREN_LOADED";

export const ORG_CHART_MORE_USERS_LOADED = "ORG_CHART_MORE_USERS_LOADED";

export const PROJECTS_USERS_LOADED = "PROJECTS_USERS_LOADED";

export const PROJECTS_ROLES_LOADED = "PROJECTS_ROLES_LOADED";

export const CHANGE_ROTATION = "CHANGE_ROTATION";

export const IFRAME_HAS_CHANGES = "IFRAME_HAS_CHANGES";

export const COMMENTS_HAVE_CHANGES = "COMMENTS_HAVE_CHANGES";

export const IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG = "IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG";

export const UPDATE_DOCUMENT_VERSION = "UPDATE_DOCUMENT_VERSION";

export const ADD_TO_BATCH = "ADD_TO_BATCH";

export const REMOVE_FROM_BATCH = "REMOVE_FROM_BATCH";

export const BATCH_LOADED = "BATCH_LOADED";

export const UNBUNDLE_BATCH = "UNBUNDLE_BATCH";

export const CHANGE_AUTOPAY_URL = "CHANGE_AUTOPAY_URL";

export const HIDE_AUTOPAY_IFRAME = "HIDE_AUTOPAY_IFRAME";

export const PROJECTS_USERS_ROLES_LOADING = "PROJECTS_USERS_ROLES_LOADING";

export const COST_UNIT_ROLES_LOADED = "COST_UNIT_ROLES_LOADED";

export const COST_UNIT_WARNINGS_LOADED = "COST_UNIT_WARNINGS_LOADED";

export const APPROVERS_TREE_ROOT_LOADED = "APPROVERS_TREE_ROOT_LOADED";

export const APPROVERS_NODES_LOADED = "APPROVERS_NODES_LOADED";

export const COST_UNIT_ROLE_TYPES_LOADED = "COST_UNIT_ROLE_TYPES_LOADED";

export const COST_UNITS_ROLES_SORT_CHANGED = "COST_UNITS_ROLES_SORT_CHANGED";

export const CLOSE_COST_UNIT_NODE = "CLOSE_COST_UNIT_NODE";

export const COST_UNITS_BRANCHES_LOADED = "COST_UNITS_BRANCHES_LOADED";

export const WORKFLOWS_AMOUNT_LIMITS_LOADED = "WORKFLOWS_AMOUNT_LIMITS_LOADED";

export const SET_STEP_RULE = "SET_STEP_RULE";

export const SET_STEP_RULE_CONDITION = "SET_STEP_RULE_CONDITION";

export const SET_STEP_RULE_CONDITION_BY_NUMBER = "SET_STEP_RULE_CONDITION_BY_NUMBER";

export const DOCUMENT_TYPES_LOADED = "DOCUMENT_TYPES_LOADED";

export const WORKFLOWS_HISTORY_LOADING = "WORKFLOWS_HISTORY_LOADING";

export const WORKFLOWS_HISTORY_LOADED = "WORKFLOWS_HISTORY_LOADED";

export const CLEAR_WORKFLOWS_HISTORY = "CLEAR_WORKFLOWS_HISTORY";

export const MORE_WORKFLOWS_HISTORY_LOADED = "MORE_WORKFLOWS_HISTORY_LOADED";

export const OLD_WORKFLOW_DETAILS_LOADED = "OLD_WORKFLOW_DETAILS_LOADED";

/**
 * Exceptions
 */
export const ROLE_NAME_EXCEPTIONS_LOADED = "ROLE_NAME_EXCEPTIONS_LOADED";

export const ROLE_NAME_EXCEPTION_DELETED = "ROLE_NAME_EXCEPTION_DELETED";
